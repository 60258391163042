import React, { Component } from 'react';
// import logo from './logo.svg';
import graveguide from './graveguide.svg'
import './App.css';

require('./graveguide.svg');

class App extends Component {
  constructor(props) {
    super(props);
    document.title = 'Grave Guide';
  }
  
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <div className="nudge">
            <img src={graveguide} className="App-logo" alt="logo" />
          </div>
          <h2>Welcome to Grave Guide</h2>
        </div>
        <p className="App-intro">
          The app will soon be launched, stay tuned!
        </p>
      </div>
    );
  }
}

export default App;
